import React from "react";
import LazyLoad from "react-lazy-load";
import "./style.scss";
import { Box } from "@mui/material";
import CustomButton from "../../../../components/custom-button";
import HeroImage from "../../../../assets/images/heroArt.png";

const Hero = ({ handleScrollTo }) => {
	return (
		<Box
			id="about-us"
			px={{ xs: "20px", md: "40px" }}
			overflow="hidden"
			height="fit-content"
			bgcolor="kamiBackground.main"
		>
			<Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
				<div className="heroLeft">
					<h1 className="heroTitle">Empowering Your Digital Dreams</h1>
					<p className="heroParagraph">
						At KamiKode, we are driven by a strong passion to transform your
						ideas into efficient and easily operational websites that excel in
						the online world. As a company focused on data-driven approaches and
						cutting-edge technology solutions for various industries, we are
						prepared to provide you with customized software development
						services, aligning with your specific deadlines and budget
						requirements.
					</p>
					<CustomButton
						color="buttonSecondary"
						handleScrollTo={handleScrollTo}
						scrollTo="contact-us"
						label="Let's Talk"
					/>
				</div>

				{/* Use LazyLoad to lazy load the image */}
				<LazyLoad>
					<Box
						component="img"
						src={HeroImage}
						alt="Kamikode working on a new web"
						sx={{
							width: { xs: "100%", md: "50%" },
						}}
					/>
				</LazyLoad>
			</Box>
		</Box>
	);
};

export default Hero;
