import React from "react";
import NavBar from "./components/navbar";
import "./style.scss";
import HomePage from "./pages/home-page";

const Client = () => {
	return (
		<div className="clientWrapper">
			<NavBar />
			<HomePage />
		</div>
	);
};

export default Client;
