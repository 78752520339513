import * as React from "react";
import {
	Box,
	Grid,
	Link,
	Typography,
	Container,
	IconButton,
	useTheme,
	Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KamiKodeLogo from "../../assets/images/logoWhiteWide.png";

const FooterComponent = ({ handleScrollTo }) => {
	const theme = useTheme();
	const socialMediaLinks = {
		facebook: "#",
		twitter: "#",
		instagram: "#",
	};
	return (
		<Box
			id="contact-us"
			bgcolor={theme.palette.secondary.main}
			px={{ md: "60px", xs: "20px" }}
			pt="60px"
			pb="30px"
		>
			<Container maxWidth={false}>
				<Grid container spacing={2} justifyContent="space-between">
					<Grid item xs={12} sm={12} md={3} color="kamiWhite">
						<Box
							component="img"
							className="kamiLogo"
							src={KamiKodeLogo}
							alt="The KamiKode Logo"
						/>
						<Typography color="primary.main">
							Coding Elevated, Technology Redefined
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						sm={6}
						md={2}
						color="primary.main"
						pr="16px"
						sx={{ pr: "16px" }}
					>
						<Typography
							variant="subtitle1"
							color="primary.main"
							gutterBottom
							sx={{
								textAlign: (theme) => ({
									xs: "left",
									md: "center",
								}),
								pb: (theme) => ({ md: "40px", xs: "10px" }),
								pt: (theme) => ({ md: "0px", xs: "20px" }),
								pl: (theme) => ({ xs: "8px" }),
							}}
						>
							Follow Us
						</Typography>

						<Box
							display="flex"
							justifyContent={{ md: "center", sm: "flex-start" }}
							pb={{ xs: "20px", md: "0px" }}
						>
							<IconButton
								aria-label="Facebook"
								color="inherit"
								component="a"
								href={socialMediaLinks.facebook}
							>
								<FacebookIcon />
							</IconButton>
							<IconButton
								aria-label="Twitter"
								color="inherit"
								component="a"
								href={socialMediaLinks.twitter}
							>
								<TwitterIcon />
							</IconButton>
							<IconButton
								aria-label="Instagram"
								color="inherit"
								component="a"
								href={socialMediaLinks.instagram}
							>
								<InstagramIcon />
							</IconButton>
						</Box>
					</Grid>
					<Grid
						xs={12}
						sm={12}
						md={3}
						pl={{ md: "16px", xs: "24px" }}
						pr="16px"
						color="primary.main"
						pt="16px"
					>
						<Typography color="primary.main" gutterBottom>
							Contact Us
						</Typography>
						<Box display="flex" alignItems="center" mb={1}>
							<PhoneIcon sx={{ mr: 1 }} />
							<Link href="#" color="inherit" fontSize="16px">
								+961 76099556
							</Link>
						</Box>
						<Box display="flex" alignItems="center">
							<MailOutlineIcon sx={{ mr: 1 }} />
							<Link href="#" color="inherit" fontSize="16px">
								info@kamikode.com
							</Link>
						</Box>
					</Grid>
				</Grid>
				<Box
					display="flex"
					gap="20px"
					justifyContent={"space-between"}
					color="primary.main"
					px={{ md: "30% ", sm: "0" }}
					py="60px"
				>
					<Button onClick={() => handleScrollTo("about-us")}>
						<Typography fontSize={{ md: "14px", xs: "12px" }}>
							About Us
						</Typography>
					</Button>
					<Button
						onClick={() => handleScrollTo("what-we-offer")}
						fontSize={"16px"}
					>
						<Typography fontSize={{ md: "14px", xs: "12px" }}>
							What we Offer
						</Typography>
					</Button>
					<Button onClick={() => handleScrollTo("contact-us")} fontSize="16px">
						<Typography fontSize={{ md: "14px", xs: "12px" }}>
							Contact Us
						</Typography>
					</Button>
				</Box>
				<Typography
					variant="body2"
					align="center"
					sx={{ pt: 4 }}
					color="primary.main"
				>
					© 2024 KamiKode Co. All rights reserved.
				</Typography>
			</Container>
		</Box>
	);
};

export default FooterComponent;
