const mockAccordionData = [
	{
		accordionTitle: "Web Development",
		accordionContent:
			"At KamiKode, we specialize in crafting custom websites tailored to your business needs. Our web development services include building responsive and user-friendly websites that leave a lasting impression.",
	},
	{
		accordionTitle: "UI/UX Design",
		accordionContent:
			"Elevate your online presence with KamiKode's UI/UX design expertise. We create visually stunning and intuitive user interfaces, ensuring a seamless and enjoyable experience for your audience.",
	},
	{
		accordionTitle: "Digital Marketing",
		accordionContent:
			"Drive your business growth with our strategic digital marketing solutions. KamiKode offers a range of services, including SEO, social media marketing, and content creation, to boost your online visibility and engagement.",
	},
	{
		accordionTitle: "Advising & Guidance",
		accordionContent:
			"Receive personalized advising and guidance from the experts at KamiKode. We work closely with you to understand your business goals, providing insights and recommendations to help you make informed decisions.",
	},
	{
		accordionTitle: "Support and Maintenance",
		accordionContent:
			"KamiKode ensures the ongoing success of your digital initiatives through reliable support and maintenance services. Count on us to keep your website running smoothly and to address any technical issues promptly.",
	},
];

export default mockAccordionData;
