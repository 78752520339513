import React from "react";
import Client from "./projects/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./stylesheets/main.scss";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<div className="App">
					<Client />
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;
