import React from "react";
import Hero from "./components/hero";
import PageWrapper from "../../components/wrapper";
import WhatWeOffer from "./components/what-we-offer";
import FooterComponent from "../../components/footer";
import { Box } from "@mui/material";

const HomePage = () => {
	const handleScrollTo = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<PageWrapper>
			<Box>
				<Hero handleScrollTo={handleScrollTo} />
				<WhatWeOffer handleScrollTo={handleScrollTo} />
				<FooterComponent handleScrollTo={handleScrollTo} />
			</Box>
		</PageWrapper>
	);
};

export default HomePage;
