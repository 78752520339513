import React from "react";
import "./style.scss";
import Bubble from "./components/bubble";
import HeadArt from "../../../../assets/images/headArt.png";
import AccordionChild from "../../../../components/accordion-item";
import { Accordion } from "@szhsin/react-accordion";
import mockAccordionData from "./mock-data";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CustomButton from "../../../../components/custom-button";

const WhatWeOffer = ({ handleScrollTo }) => {
	const imgContainerStyle = {
		flex: "2",
		backgroundImage: `url(${HeadArt})`,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
	};

	return (
		<Box id="what-we-offer" py="60px" px={{ md: "40px", xs: "20px" }}>
			<Box
				bgcolor="kamiBackground.main"
				p={{ md: "40px 56px", xs: "20px 23px" }}
				borderRadius="20px"
				display="flex"
				minHeight="80vh"
				flexDirection={{ xs: "column", md: "row" }}
				gap="20px"
			>
				<Box
					display="flex"
					flexDirection="column"
					width={{ md: "50%", sm: "100%" }}
					padding={{ md: "0", sm: "20px 0" }}
				>
					<Typography
						variant="h3"
						pb="20px"
						sx={{ fontSize: { xs: "30px", md: "34px" } }}
					>
						What we offer
					</Typography>
					<Typography variant="body2" pb="20px">
						We offer services for small to medium-scale businesses and startups
						in multiple industries, including the following:
					</Typography>
					<div className="bubbleContainer">
						<Bubble text="EdTech"></Bubble>
						<Bubble text="E-Commerce"></Bubble>
						<Bubble text="Retail"></Bubble>
					</div>
					<Typography variant="body2" py="20px">
						Showcase your business with a high-quality custom website now.
					</Typography>
					<CustomButton
						color="buttonSecondary"
						handleScrollTo={handleScrollTo}
						scrollTo="contact-us"
						label="Contact Us"
					/>
					<div className="imgContainer" style={imgContainerStyle}></div>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					width={{ md: "50%", sm: "100%" }}
					padding={{ md: "0", sm: "20px 0" }}
				>
					<Accordion class="szh-accordion" transition transitionTimeout={250}>
						{mockAccordionData.map((accordion, index) => (
							<AccordionChild
								key={index}
								accordionTitle={accordion.accordionTitle}
								accordionContent={accordion.accordionContent}
							/>
						))}
					</Accordion>
				</Box>
			</Box>
		</Box>
	);
};

export default WhatWeOffer;
