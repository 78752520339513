import { Button, Typography } from "@mui/material";
import React from "react";

const CustomButton = ({ handleScrollTo, scrollTo, label, ...props }) => {
	return (
		<Button
			{...props}
			variant="contained"
			sx={{
				borderRadius: "25px",
				maxWidth: "200px",
			}}
			onClick={() => handleScrollTo(`${scrollTo}`)}
			size="small"
		>
			<Typography fontWeight="800" fontSize="14px">
				{label}
			</Typography>
		</Button>
	);
};

export default CustomButton;
