import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: "Quicksand",
		h1: {
			fontSize: "2.5rem",
			fontWeight: 600,
		},
		h2: {
			fontSize: "2rem",
			fontWeight: 600,
		},
		h3: {
			fontSize: "1.5rem",
			fontWeight: 600,
		},
		body1: {
			fontSize: "1rem",
			fontWeight: "500",
		},
		body2: {
			fontSize: "0.875rem",
			fontWeight: "500",
		},
	},
	palette: {
		primary: {
			main: "#FAFAFE",
			contrastText: "#003047",
		},
		secondary: {
			main: "#003047",
		},
		kamiKorange: {
			main: "#F48830",
		},
		kamiWhite: {
			main: "#D9D9D9",
		},
		kamiLightPurple: {
			main: "#494A7D",
		},
		kamiBackground: {
			main: "#F1F1FF",
		},
		buttonSecondary: {
			main: "#ecae10",
		},
		buttonMain: {
			main: "#003047",
			contrastText: "#F1F1FF",
		},
	},
	components: {
		MuiLink: {
			variants: [
				{
					props: { variant: "dark" },
					style: {
						color: "darkblue",
						"&:hover": {
							textDecoration: "underline",
						},
					},
				},
				{
					props: { variant: "light" },
					style: {
						color: "red",
						"&:hover": {
							textDecoration: "underline",
						},
					},
				},
			],
		},
	},
});
