import React from "react";
import "./style.scss";
const Bubble = (props) => {
	return (
		<div className="bubble">
			<h1 className="bubbleText">{props.text}</h1>
		</div>
	);
};

export default Bubble;
