import React from "react";
import { AccordionItem } from "@szhsin/react-accordion";
import "./style.scss";
import chevronDown from "./chevron-down.svg";
import { Typography } from "@mui/material";

<img className="chevron-down" src={chevronDown} alt="Chevron Down" />;

function AccordionChild({ accordionTitle, accordionContent }) {
	return (
		<AccordionItem
			header={
				<>
					<accordionTitle />
					<Typography variant="body1" fontWeight="600">
						{accordionTitle}
					</Typography>
					<img className="chevron-down" src={chevronDown} alt="Chevron Down" />
				</>
			}
		>
			<Typography fontWeight="500">{accordionContent}</Typography>
		</AccordionItem>
	);
}

export default AccordionChild;
